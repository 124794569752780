*, :before, :after {
  box-sizing: border-box;
  vertical-align: baseline;
  scroll-behavior: smooth;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  color: #003b79;
  background-color: #0000;
  background-image: radial-gradient(circle at 0 0, #003b7a, #0000), radial-gradient(circle at 100% 100%, #0da1ac, #0000), linear-gradient(135deg, #d113f7 0%, #ff0fdf 100%);
  background-position: 0 0, 0 0, 0 0;
  background-repeat: repeat, repeat, repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  font-family: Roboto, sans-serif;
}

.cab {
  z-index: 99;
  background-color: #00264e;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  top: 0;
}

.navbar {
  z-index: 99;
  -webkit-backdrop-filter: blur(10px);
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  position: fixed;
  top: 3.5%;
}

.navbar .navbar-brand {
  color: #f2f2ff;
  text-shadow: 0 2px 2px #00264e;
  font-size: 1.5rem;
}

.navbar .navbar-toggler {
  background: #00264e;
}

.navbar a {
  color: #f2f2ff;
  text-shadow: 0 2px 2px #00264e;
  font-size: 1rem;
}

.navbar a:hover {
  color: #00a078;
}

.navbar a.nav-link.active {
  background: #00264e;
  border-radius: 6px;
  margin-top: 4px;
}

.navbar a.nav-link.active:hover {
  color: #00a078;
  background: #00458f;
}

.hero {
  color: #f2f2ff;
  padding-top: 8rem;
}

.hero h1 {
  text-shadow: 0 2px 2px #00264e;
  font-size: 4rem;
  font-weight: 700;
}

.hero p {
  font-size: 2rem;
  font-weight: 400;
}

.categories i {
  color: #883381;
  text-shadow: 0 1px 1px #f2f2ff;
}

.categories .slogan {
  color: #f2f2ff;
  padding: 2rem 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.categories .card {
  background-color: #0000;
  background-image: radial-gradient(circle at 0 0, #fffc, #0000), radial-gradient(circle at 100% 100%, #e5f0edb3, #0000), linear-gradient(135deg, #f3ffc7b3 0%, #d7e6d0b3 100%);
  background-position: 0 0, 0 0, 0 0;
  background-repeat: repeat, repeat, repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
  border: none;
  transition: box-shadow .3s;
  box-shadow: 0 4px 4px #003b79;
}

.categories .card:hover {
  box-shadow: 0 4px 8px #00264e;
}

.categories .card .card-body {
  border-radius: 6px;
}

.categories .card .card-title {
  color: #003b79;
  text-shadow: 0 1px 1px #f2f2ff;
  background: linear-gradient(90deg, #d4d2cee6 0%, #d1d0cccc 80%);
  border-radius: 6px;
  width: 100%;
  margin-bottom: 1rem;
  padding: .5rem;
  font-size: 2rem;
  font-weight: 700;
}

.categories .card p {
  color: #00264e;
  font-size: 1rem;
  font-weight: 400;
}

.categories .card ul {
  padding: 0;
}

.categories .card ul li {
  margin-bottom: .5rem;
}

.categories .card ul li a {
  color: #f2f2ff;
  background: linear-gradient(#002c5c 0%, #003b7a 70%);
  border-radius: 6px;
  width: 100%;
  padding: .5rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: none;
  transition: transform, color .2s;
  display: block;
}

.categories .card ul li a:hover {
  color: #00a078;
  background: #00458f;
}

@media (width <= 768px) {
  .categories .card ul li a {
    padding: 1rem;
  }
}

.seo-banner img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 15rem;
}

.seo-content {
  color: #f2f2ff;
}

.seo-content h2 {
  text-shadow: 0 2px 2px #00264e;
  font-size: 2.5rem;
  font-weight: 700;
}

.seo-content p {
  font-size: 1.3rem;
  font-weight: 400;
}

.copy {
  text-align: center;
  background-color: #0000;
  padding: 1rem 0 60px;
}

.copy a {
  color: #f2f2ff;
  text-decoration: none;
}

.copy p {
  color: #f2f2ff;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

footer {
  -webkit-backdrop-filter: blur(10px);
  background: #00264e;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

footer img {
  width: 2.5rem;
}

footer p {
  color: #f2f2ff;
  margin: 0;
  font-size: 12px;
}

@media (width <= 768px) {
  .hero {
    padding-top: 10rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1rem;
  }
}
/*# sourceMappingURL=index.c9ba5827.css.map */
